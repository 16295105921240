import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/gallery/home.svg";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <section className="heros-sec">
      <Container className="homecontainer">
        <Row>
          <Col lg="6" className="hero-content-col">
             <div className="hero__content">
      <div className="mb-4 hero__title">
        <h3>Welcome to Our School</h3>
        
      </div>
      <p>
      Where we believe in the supreme power of knowledge. Our institution is dedicated to not only fostering academic excellence but also ensuring the holistic development of each student. We go beyond traditional teaching methods, creating an environment where creativity, critical thinking, and innovation flourish. With state-of-the-art facilities, including a modern computer lab, alongside our team of dedicated educators and a supportive community, we empower students to unlock their full potential and embark on a lifelong journey of learning.       
      </p>
    </div>
        
           <div className="herosecbtn">
             <a href="/About" target="_blank">
             <button className="btn herosecbtnbtn"><i className="fas fa-trophy" style={{ marginRight: '5px' }}></i>Explore More!</button>
             </a>
           </div>


          </Col>

          <Col lg="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
