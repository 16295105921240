import Admissionherosec from "../components/admissionherosec/Admissionherosec";
import Admissions from "../components/admissions/Admissions";
import Enquiry from "../components/Enquiry/Index"
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";



const Admission = () => {

  return (
    <>
      <Navbar />
      <Admissionherosec />
      <Admissions />
      <Enquiry />
      <F1/>
    </>
  )
}

export default Admission;