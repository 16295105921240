// import processchool from "../../assests/images/gallery/stepprocess.png";
import Mamatha from "../../assests/images/gallery/Mamatha.png";
import Jalaja from "../../assests/images/gallery/Jalaja.png";
import Shakeerbasha from "../../assests/images/gallery/Shakeerbasha.png";
import Shakunthala from "../../assests/images/gallery/Shakunthala.png";
import Chandra from "../../assests/images/gallery/Chandra.png";
import Farheen from "../../assests/images/gallery/Farheen.png";
import Rakshita from "../../assests/images/gallery/Rakshita.png";
import Prakash from "../../assests/images/gallery/Prakash.png";
import Aswath from "../../assests/images/gallery/Aswath.png";
import Surekha from "../../assests/images/gallery/Surekha.png";
import Nagarathna from "../../assests/images/gallery/Nagarathna.png";
import Kalai from "../../assests/images/gallery/Kalai.png";
import PrePrimary from "../../assests/images/gallery/PrePrimary.svg";
import PrimarySchool from "../../assests/images/gallery/PrimarySchool.svg";
import MiddleSchool from "../../assests/images/gallery/MiddleSchool.svg";
import SecondarySchool from "../../assests/images/gallery/SecondarySchool.svg";
import Latha from "../../assests/images/gallery/Latha.png";
import Nanditha from "../../assests/images/gallery/Nanditha.png";
import Sangeetha from "../../assests/images/gallery/Sangeetha.png";
import Sathya from "../../assests/images/gallery/Sathya.png";
import Shibani from "../../assests/images/gallery/Shibani.png";
import Shruthi from "../../assests/images/gallery/Shruthi.png";
import Soumya from "../../assests/images/gallery/Soumya.png";
import Stella from "../../assests/images/gallery/Stella.png";
import Sumalata from "../../assests/images/gallery/Sumalata.png";
import Uma from "../../assests/images/gallery/Uma.png";
import Vijaylaxmi from "../../assests/images/gallery/Vijaylaxmi.png";
import Gnanendra from "../../assests/images/gallery/Gnanendra.png";
import Reshma from "../../assests/images/gallery/Reshma.png";
import Piillappa from "../../assests/images/gallery/Piillappa.png";


import "./academic.css";

const teachersdetails = [
  {
    id: "0",
    image: Mamatha,
    name: "Mamatha N R",
    qualification: "",
    subject: "English Faculty",
  },
  {
    id: "1",
    image: Jalaja,
    name: "Jalaja N T",
    qualification: "",
    subject: "Primary Science Faculty",
  },
  {
    id: "2",
    image: Shakeerbasha,
    name: "Anjum",
    qualification: "",
    subject: "Primary EVS Faculty",
  },
  {
    id: "3",
    image: Shakunthala,
    name: "Shakunthala",
    qualification: "",
    subject: "Kannada Faculty",
  },
  {
    id: "4",
    image: Chandra,
    name: "Chandra Rekha",
    qualification: "",
    subject: "Maths Faculty",
  },
  {
    id: "5",
    image: Farheen,
    name: "Farheen",
    qualification: "",
    subject: "Hindi Faculty",
  },
  {
    id: "6",
    image: Rakshita,
    name: "Vandana",
    qualification: "",
    subject: "Primary Hindi faculty",
  },

  
  {
    id: "7",
    image: Kalai,
    name: "Kalai selvi",
    qualification: "",
    subject: "Science Faculty",
  },
  {
    id: "8",
    image: Vijaylaxmi,
    name: "Vijaylaxmi Kulkarni",
    qualification: "(MA,B.Ed)",
    subject: "Hindi Faculty",
  },
  {
    id: "9",
    image: Nagarathna,
    name: "Nagarathna N",
    qualification: "(MA,B.Ed)",
    subject: "English Faculty",
  },
  {
    id: "10",
    image: Prakash,
    name: "Prakash",
    qualification: "(MA,B.Ed)",
    subject: "Kannada Faculty",
  },
  {
    id: "11",
    image: Aswath,
    name: "Aswath Narayana",
    qualification: "(MA, B.Ed,)",
    subject: "Social Faculty",
  },
  {
    id: "12",
    image: Surekha,
    name: "Surekha",
    qualification: "(BA, B.Ed)",
    subject: "Social Faculty",
  },
  {
    id: "13",
    image: Latha,
    name: "Latha R",
    qualification: "",
    subject: "Kindergarten Faculty",
  },
  {
    id: "14",
    image: Nanditha,
    name: "Nanditha S",
    qualification: "",
    subject: "Primary Faculty",
  },
  {
    id: "15",
    image: Sangeetha,
    name: "Sangeetha S B",
    qualification: "",
    subject: "Lower Primary Faculty",
  },
  {
    id: "16",
    image: Sathya,
    name: "Sathya Govindhan",
    qualification: "",
    subject: "Kindergarten Faculty",
  },
  {
    id: "17",
    image: Shibani,
    name: "Mamatha.M",
    qualification: "",
    subject: "Primary Kannada Faculty",
  },
  {
    id: "18",
    image: Shruthi,
    name: "Shruthi T S",
    qualification: "",
    subject: "Primary Maths Faculty",
  },
  {
    id: "19",
    image: Soumya,
    name: "Soumya D",
    qualification: "",
    subject: "Lower Primary Faculty",
  },
  {
    id: "20",
    image: Stella,
    name: "Stella Joyce",
    qualification: "",
    subject: "English Faculty",
  },
  {
    id: "21",
    image: Sumalata,
    name: "Sumalata",
    qualification: "",
    subject: "Kannada Faculty",
  },
  {
    id: "22",
    image: Uma,
    name: "Uma",
    qualification: "",
    subject: "Lower Primary Faculty",
  },
  {
    id: "23",
    image: Gnanendra,
    name: "Gnanendra.R",
    qualification: "",
    subject: "Physical Education Instructor",
  },
  {
    id: "24",
    image: Reshma,
    name: "Reshma Sherigar",
    qualification: "",
    subject: "Kindergarten Teacher",
  },


  {
    id: "25",
    image: Piillappa,
    name: "Piillappa.M",
    qualification: "",
    subject: "High School Kannada faculty",
  },
  
];

const Academic = () => {
  return (
    <>
      <section className="achivmentsHead">
        <h1>Our Academics</h1>
      </section>
      <section className="academicsSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 clm-padng clm-dwn">
              <div className="academicsSec-imgdiv imgdivbdr">
                <img src={PrePrimary} alt="" />
              </div>
              <div className="academicsSec-cntdiv">
                <h5 className="academicsSec-heading yloClr">
                  <span>Pre-Primary</span>
                </h5>
                <p>
                  Our Pre-Primary program focuses on providing a stimulating and
                  nurturing environment where young learners can explore, play,
                  and develop foundational skills. Through age-appropriate
                  activities and interactive learning experiences, we aim to
                  foster curiosity, creativity, and a love for learning.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 clm-padng">
              <div className="academicsSec-imgdiv imgdivbdr">
                <img src={PrimarySchool} alt="" />
              </div>
              <div className="academicsSec-cntdiv">
                <h5 className="academicsSec-heading pnkclr">Primary School</h5>
                <p>
                  In the Primary School, students embark on a journey of
                  academic discovery, building upon their foundational knowledge
                  and skills. Our curriculum encompasses subjects such as
                  English, Mathematics, Science, Social Studies, and
                  Environmental Studies. We emphasize interactive teaching
                  methods, hands-on learning activities, and problem-solving
                  skills.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 clm-padng clm-dwn">
              <div className="academicsSec-imgdiv imgdivbdr">
                <img src={MiddleSchool} alt="" />
              </div>
              <div className="academicsSec-cntdiv">
                <h5 className="academicsSec-heading skyclr">Middle School</h5>
                <p>
                  The Middle School years are a pivotal stage in a student's
                  educational journey, laying the groundwork for future academic
                  success. Our curriculum for Classes 6 to 8 encompasses a broad
                  range of subjects, including English, Mathematics, Science,
                  History, Geography, and Languages (Hindi/Sanskrit).
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 clm-padng">
              <div className="academicsSec-imgdiv imgdivbdr">
                <img src={SecondarySchool} alt="" />
              </div>
              <div className="academicsSec-cntdiv">
                <h5 className="academicsSec-heading blkclr">
                  Secondary School
                </h5>
                <p>
                  In Classes 9 and 10, students prepare for the Indian
                  Certificate of Secondary Education (ICSE) examination. Our
                  rigorous academic program equips students with the knowledge,
                  skills, and confidence to excel in their examinations and
                  beyond. Subjects covered include English, Mathematics,
                  Physics, Chemistry, Biology, History, Geography, and a choice
                  of Second Language.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="achivmentsHead">
        <h1>Our Teachers</h1>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {teachersdetails.map(function (data) {
              return (
                <>
                  <div className="col-lg-3 col-md-4 col-sm-6 marbottm">
                    <div className="card teacherCard">
                      <div className="teacherImage">
                        <img src={data.image} alt="" />
                      </div>
                      <div className="teacherDetails">
                        <h5>{data.name}</h5>
                        <h6>{data.qualification}</h6>
                        <p>{data.subject}</p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Academic;
