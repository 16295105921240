import React from "react";

const FreeCourseCard = (props) => {
  // const { imgUrl, title, students, rating } = props.item;
  const { imgUrl, title } = props.item;

  return (
    <div className="single__free__course">
      <div className="free__course__img">
        <img src={imgUrl} alt="" className="w-100" />
        {/* <button className="btn free__btn">Free</button> */}
      </div>

      <div className="free__course__details">
        <h6>{title}</h6>        
      </div>
    </div>
  );
};

export default FreeCourseCard;
