import AreasOfCurriculum from "../components/AreasOfCurriculum/index";
import Acrherosec from "../components/acrherosec/Acrherosec";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";


const ACurriculum = () => {
  return (
    <>
      <Navbar />
      <Acrherosec />
      <AreasOfCurriculum />
      <F1 />
    </>
  );
};

export default ACurriculum;
