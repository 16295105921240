import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faLightbulb,
  faLaptop,
  faBrain,
} from "@fortawesome/free-solid-svg-icons";
import shape1img from "../../assests/ImageCurriclm/testimonial_1.jpg";
import approach3 from "../../assests/ImageCurriclm/approach3.svg";
import './curriachiever.css'


const CurriAchiever = () => {
  return (
    <div>
        <section className="achivmentsHead">
        <h1>Our Educational Framework</h1>
      </section>
           <section
        className=" landing-page-section about--area pt-120 pb-60 wow fadeInUp"
        data-wow-duration=".8s"
        data-wow-delay=".4s"
        style={{
          visibility: "visible",
          animationDuration: "0.50s",
          animationDelay: "0.50s",
          backgroundColor: "white",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 col-md-12 col-12">
              <div className="tp-ab-circle-img p-relative mb-60">
                <div className="curriculum-circle-img">
                  <img src={approach3} alt="about-img" />
                </div>
                <div className="ab-circle-shape">
                  <img
                    src={shape1img}
                    alt="about-shape"
                    className="ab-circle-two"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-6 col-md-12 col-12">
              <div className="tp-abcircle-content ">
                <div className="section-title">
                  <h3 className="tp-section-title mb-20">
                    Empowering Growth through Comprehensive Education
                  </h3>

                  <p>
                    At <b> MACAULAY </b>,The educational framework at our
                    English School is carefully structured to provide a dynamic
                    and enriching learning environment. Our approach integrates
                    personalized learning strategies, innovative teaching
                    methods, and character development initiatives to foster
                    holistic growth in our students. Through a balance of
                    academic rigor, creative expression, and community
                    engagement, we aim to empower students with the skills,
                    knowledge, and values necessary for success in the modern
                    world.{" "}
                  </p>
                </div>
                <div class="about-circle-list mb-40">
                  <div className="row text-center cmedfmIcon">
                    <div className="col-sm-6">
                      <FontAwesomeIcon
                        icon={faBook}
                        className="icon-background"
                      />
                      <p>Diverse Subjects</p>
                    </div>
                    <div className="col-sm-6">
                      <FontAwesomeIcon
                        icon={faLightbulb}
                        className="icon-background"
                      />
                      <p>Interactive Learning</p>
                    </div>
                    <div className="col-sm-6">
                      <FontAwesomeIcon
                        icon={faLaptop}
                        className="icon-background"
                      />
                      <p>Instructional Design</p>
                    </div>
                    <div className="col-sm-6">
                      <FontAwesomeIcon
                        icon={faBrain}
                        className="icon-background"
                      />
                      <p>Critical Thinking</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default CurriAchiever