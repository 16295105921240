import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";


const Newsletter = () => {
  return (
    <section>
      <Container className="newsletter">
      <div>
        <Row className="overlay">
          <Col lg="12" className="text-center">
            <h2 className="mb-4 mt-4">School Mission and Values</h2>
           

            <p className="readable-animation">
                   <q>Knowledge is Supreme</q>
            </p>

           
          </Col>
        </Row>
        </div>
      </Container>
    </section>
  );
};

export default Newsletter;
