import Foundation from "../components/foundations/Index"
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Foundationhero from "../components/foundationhero/Foundationhero";
// import Herosection from "../components/herosection/Herosection";


const Foundations = () => {
  return (
    <>
      <Navbar />
      {/* <Herosection /> */}
      <Foundationhero />
      <Foundation />
     <F1/>
    </>
  );
};
 export default Foundations;