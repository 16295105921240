import Habout from "../components/Habout/index";
import AboutHero from "../components/AboutHero/MovieNight";
import GridA from "../components/GridA/Grids";
import Enquiry from "../components/Enquiry/Index";
import ScrollImage from "../components/scrollImage/ScrollImage";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Aboutherosec from "../components/aboutherosec/Aboutherosec";


const Ahome = () => {
  return (
    <>
      <Navbar />
      {/* <Herosection /> */}
      <Aboutherosec />
      <AboutHero />
      <GridA />
      <ScrollImage />
      <Habout />
      <Enquiry />
      <F1 />
    </>
  );
};

export default Ahome;
