
/* eslint-disable */

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Infrastructures from './pages/Infrastructures';
import Gallery from './pages/Galleries';
import Ahome from './pages/About';
import Foundations from './pages/Foundations';
import ACurriculum from './pages/ACurriculum';
import Curriculum from './pages/Curriculum';
import ManagementsPage from './pages/ManagementsPage';
import Contacts from './pages/Contacts';
import Admission from './pages/Admission';
import Enquiry from './components/Enquiry/Index';
import Achiever from './pages/Achiever';
import Academics from './pages/Academics';
import Facilities from './pages/Facilities';
import PrincipalPage from './pages/PrincipalPage';

function App() {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/infrastructures" element={<Infrastructures />} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/Ahome" element={<Ahome/>} />
          <Route path="/Foundations" element={<Foundations/>} />
          <Route path="/ACurriculum" element={<ACurriculum/>} />
          <Route path="/CurriculumModels" element={<Curriculum/>} />
          <Route path="/ManagementsPage" element={<ManagementsPage/>} />
          <Route path="/Contacts" element={<Contacts/>} />
          <Route path="/Achievers" element={<Achiever/>} />
          <Route path="/Admission" element={<Admission/>} />
          <Route path="/Enquiry" element={<Enquiry/>} />
          <Route path="/Academics" element={<Academics/>} />
          <Route path="/Facilities" element={<Facilities/>} />
          <Route path="/PrincipalDesk" element={<PrincipalPage/>} />                   
        </Routes>
    </Router>
  );
}

export default App;
