import Enquiry from "../components/Enquiry/Index"
import MovieNightSection from "../components/MovieNightSection/MovieNight";
import Grid from "../components/Grid/Grids";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Facilityherosec from "../components/facilityherosec/Facilityherosec";

const Facilities = () => {
  return (
    <>
      <Navbar />
      <Facilityherosec />
      <MovieNightSection />
      <Grid />      
      <Enquiry />
      <F1/>
    </>
  );
};

export default Facilities;
