import "./acadmicherosec.css"

const Acadmicherosec = () => {

  return (
    <>
    <section className="navherosection acadmicherosec">  
      <div className="container fs-1 flex-column">
        <div className="acadmicherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Our Academics</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Acadmicherosec;