import Academic from "../components/academic/Academic";
import Acadmicherosec from "../components/acadmicherosec/Acadmicherosec";
import Enquiry from "../components/Enquiry/Index";
 import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
// import Herosection from "../components/herosection/Herosection";


const Academics = () => {

  return (
    <>
      <Navbar />
      {/* <Herosection /> */}
      <Acadmicherosec />
      <Academic />
      <Enquiry />
      <F1 />
    </>
  )
}

export default Academics;