import React, { useState, useRef } from "react";
import gif1 from "../../assests/contact/gif1.gif";
import gif2 from "../../assests/contact/gif2.gif";
import gif3 from "../../assests/contact/gif3.gif";
import emailjs from '@emailjs/browser';
import "./contact.css";

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault()
        alert('Form submitted successfully!'); // Show alert when form is submitted

        emailjs
            .sendForm('service_gvi31tk', 'template_4admikq', form.current, {
                publicKey: 'RACAaW-PJOHg2Ml6Y',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };
    const map =
        'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15556.965180255798!2d77.6421465!3d12.892199949999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1708510965345!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade';
    return (

        <div>
            <>
                <section className="achivmentsHead">
                    <h1>Contact Us</h1>
                </section>
                <section className="wpo-contact-pg-section section-padding">
                    <div className="contact-container">
                        <div className="row">
                            <div className="col col-lg-12 col-md-12">
                                <div className="office-info">
                                    <div className="row">
                                        <div className=" col-lg-4 col-md-4 ">
                                            <div className="office-info-item">
                                                <div className="office-info-icon">
                                                    <div className="icon">
                                                        <img className="gifimg" src={gif3} alt="" />
                                                    </div>
                                                </div>
                                                <div className="office-info-text">
                                                    <h2>Address</h2>
                                                    <p>Kudlu Gate, Hosapalya Main Road, Banglore Karnataka(560068)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-4 ">
                                            <div className="office-info-item">
                                                <div className="office-info-icon">
                                                    <div className="icon">
                                                        <img className="gifimg" src={gif1} alt="" />
                                                    </div>
                                                </div>
                                                <div className="office-info-text">
                                                    <h2>Email Us</h2>
                                                    <p>contactus@newmacaulayenglishschool.com</p>
                                                    <p>macaulayschool2010@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" col-lg-4 col-md-4 ">
                                            <div className="office-info-item">
                                                <div className="office-info-icon">
                                                    <div className="icon">
                                                        <img className="gifimg" src={gif2} alt="" />
                                                    </div>
                                                </div>
                                                <div className="office-info-text">
                                                    <h2>Call Now</h2>
                                                    <p>+91 080-49524676 </p>
                                                    <p>+91 9739156824</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-contact-title">
                                    <h2>Have Any Question?</h2>
                                    <p>It is a long established fact that a reader will be distracted content of a page when looking.</p>
                                </div>
                                <div className="wpo-contact-form-area">
                                    <form className="contact-validation-active" ref={form} onSubmit={sendEmail}>
                                        <div className="row">
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Your Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-6 col-12">
                                                <div className="form-field">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Your Email"

                                                    />
                                                </div>
                                            </div>
                                            <div className="col col-lg-12 col-12">
                                                <textarea
                                                    type="text"
                                                    name="message"
                                                    placeholder="Message"
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="submit-area">
                                            <button type="submit" className="theme-btn">
                                                Submit Now
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="wpo-contact-map-section">
                        <div className="wpo-contact-map">
                            <iframe
                                title="Map"
                                src={map}
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />
                        </div>
                    </section>
                </section>
            </>
        </div>
    );
};

export default Contact;
