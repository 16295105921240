import React from 'react';
import "./Heading.css";

const InfrastructureTitle = () => {
  return (
    <div className="infra-title">
      <h2 className='infra-title-head'>School Facilities</h2>
    </div>
  );
}

export default InfrastructureTitle;
