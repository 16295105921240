import Achievers from "../components/Achievers/Achievers";
import Enquiry from "../components/Enquiry/Index"
import Achiverherosec from "../components/achiverherosec/Achiverherosec";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";


const Achiever = () => {

  return (
    <>
      <Navbar />
      <Achiverherosec />
      <Achievers />
      <Enquiry />
      <F1/>
    </>
  )
}

export default Achiever;