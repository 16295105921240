import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faContactBook,
  faSchool,
  faServer,
  faPeopleGroup,
  faGraduationCap,
} from "@fortawesome/free-solid-svg-icons";

import "./navbar.css";

import logo from "../../assests/images/navBarImages/MHS.png";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid navcontainer">
          <a href="/" className="navbar-logo">
            <img className="navbar-logo-logo" src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end side-bar-div"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <a href="/" className="navbar-logo">
                <img className="navbar-logo-logo" src={logo} alt="" />
              </a>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1">
                <li className="nav-item mainmenu">
                  <a href="/" className="nav-link mainmenua active" aria-current="page">
                    <span><FontAwesomeIcon className="navbarMenuIcon" icon={faHome} />
                    Home</span>
                  </a>
                </li>
                <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon
                      className="navbarMenuIcon"
                      icon={faPeopleGroup}
                    />
                    About</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/About" className="nav-link dropdwnmenua">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/Foundations" className="nav-link dropdwnmenua">
                        Foundational Pillars
                      </a>
                    </li>
                    <li>
                      <a href="/ManagementsPage" className="nav-link dropdwnmenua">
                        Management
                      </a>
                    </li>
                    <li>
                      <a href="/PrincipalDesk" className="nav-link dropdwnmenua">
                      Principal Desk
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mainmenu dropdown">
                <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon
                      className="navbarMenuIcon"
                      icon={faGraduationCap}
                    />
                    Academics</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                    <a href="/Academics" className="nav-link dropdwnmenua">
                    Our Academics
                      </a>
                    </li>
                    <li>
                    <a href="/CurriculumModels" className="nav-link dropdwnmenua">
                    Curriculum Model
                      </a>
                    </li>
                    <li>
                    <a href="/ACurriculum" className="nav-link dropdwnmenua">
                    Areas of Curriculum
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mainmenu dropdown">
                  <a
                    className="nav-link mainmenua dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span><FontAwesomeIcon
                      className="navbarMenuIcon"
                      icon={faServer}
                    />
                    Facilities</span>
                  </a>
                  <ul className="dropdown-menu dropdwnmenu">
                    <li>
                      <a href="/Facilities" className="nav-link dropdwnmenua">
                        Our Facilities
                      </a>
                    </li>
                    <li>
                      <a href="/infrastructures" className="nav-link dropdwnmenua">
                        Infrastructure
                      </a>
                    </li>
                    <li>
                      <a href="/gallery" className="nav-link dropdwnmenua">
                        Gallery
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item mainmenu">
                  <a href="/Admission" className="nav-link mainmenua">
                    <span><FontAwesomeIcon
                      className="navbarMenuIcon"
                      icon={faSchool}
                    />
                    Admission</span>
                  </a>
                </li>
                <li className="nav-item mainmenu">
                  <a href="/Achievers" className="nav-link mainmenua">
                    <span><FontAwesomeIcon
                      className="navbarMenuIcon"
                      icon={faContactBook}
                    />
                    Achievers</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
