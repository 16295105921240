import "./foundationhero.css"

const Foundationhero = () => {

  return (
    <>
    <section className="navherosection foundationherosec">  
      <div className="container fs-1 flex-column">
        <div className="foundationherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Foundational Pillars</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Foundationhero;