import React, { Fragment} from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComputer, faBookOpenReader, faBaseball, faBrain, faCalendar, faSchool } from '@fortawesome/free-solid-svg-icons'

import "./infrastructure.css";
import infra2 from "../../assests/images/gallery/infra2.svg"
import classroom from '../../assests/images/gallery/classroom.png'
import { School } from "@mui/icons-material";

const Infrastructure = () => {

  return (
    <>

<section className="gallery.page.details graycolor">
    
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-6">
          <h2 className="gallery_content_head">Fostering Excellence: </h2>
          <h5>Exploring Macaulay School's Infrastructure</h5>  <br />        
          <p>The infrastructure of our School is meticulously designed to provide students with a stimulating environment conducive to academic growth, creativity, and holistic development.</p>
          <p>Our classrooms are equipped with modern teaching aids, including interactive whiteboards and multimedia projectors, to facilitate dynamic and engaging learning experiences. Additionally, our well-equipped laboratories enable students to conduct experiments and hands-on activities, fostering a deeper understanding of scientific concepts. Overall, the infrastructure at Macaulay School is designed to inspire and empower students.</p>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="content-img content-img-left">          
          <img src={infra2} alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>

      <section className="obg" >
      <div className="container infracontainer">
      <h1>About Macaulay Premises</h1>
        <div className="container">
          <div className="row">
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd schoolbg infracardhight">
              <h5 className="admissionKeyPnt whtclr">About Our school premises</h5>
              <p className="keypntpara whtclr">
              Our school promises a nurturing environment where every student is encouraged to thrive academically, socially, and emotionally. With dedicated educators who prioritize individualized attention, we foster a love for learning while providing the necessary support for each student to reach their full potential from innovative teaching methods.
              </p>
              </div>
            </div>
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd computerbg infracardhight">
              <h5 className="admissionKeyPnt whtclr">Our Computer Lab</h5>
              <p className="keypntpara whtclr">
              In addition to providing cutting-edge technology and expert technical support, the computer lab at Macaulay School is designed to promote collaborative learning and creativity. Equipped with state-of-the-art software and interactive tools, students have the opportunity to explore coding, digital design, and multimedia production.
              </p>
              </div>
            </div>
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd librarybd infracardhight">
              <h5 className="admissionKeyPnt whtclr">Library For Students</h5>
              <p className="keypntpara whtclr">
              Our library boasts a diverse collection of books catering to various interests, reading levels, and academic needs of students. From classic literature to contemporary fiction, from science and technology to history and geography, we have something for everyone.
              Students will find a plethora of academic resources to support their studies.
              </p>
              </div>
            </div>
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd sportsbg infracardhight">
              <h5 className="admissionKeyPnt whtclr">Sports and Physical Activity</h5>
              <p className="keypntpara whtclr">
              we believe that physical activity is essential for the holistic development of our students. In addition to traditional sports such as basketball, soccer, and track and field, we offer a diverse range of physical activities to cater to the interests and abilities of all students. From yoga and dance classes to outdoor adventure programs and intramural sports leagues.
              </p>
              </div>
            </div>
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd selfbg infracardhight">
              <h5 className="admissionKeyPnt whtclr">Self Defence</h5>
              <p className="keypntpara whtclr">
              Self-defense is a vital skill that empowers individuals to protect themselves in potentially dangerous situations. Learning self-defense techniques can boost confidence, increase situational awareness, and provide the necessary tools to react effectively when faced with threats. Whether it's through martial arts training, assertiveness skills.
              </p>
              </div>
            </div>
            <div className=" col-md-4 admissionKeyPointCol">
              <div className="card admissionKeyPntCrd exambg infracardhight">
              <h5 className="admissionKeyPnt whtclr">Yearly tentative exam plan</h5>
              <p className="keypntpara whtclr">
              <ul>
                <li>FA 1 exams are in July</li>
                <li>FA 2 exams are in October</li>
                <li>Midterm SA 1 exams are in December</li>
                <li>FA 3 exams/ 1st preparatory exam at the end of January</li>
                <li>FA 4/2nd preparatory exams in February starting</li>
                <li>3rd preparatory exams in February end</li>
              </ul>
              </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
    </>
  );
};

export default Infrastructure;
