import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Curri from "../components/curri/Curri";
import CurriAchiever from "../components/curri/CurriAchiever";
import Enquiry from "../components/Enquiry/Index";
import Crmodelherosec from "../components/crmodelherosec/Crmodelherosec";

const Curriculum = () => {
  return (
    <>
      <Navbar />
      <Crmodelherosec />
      <Curri/>
      <CurriAchiever/>
      <Enquiry/>
      <F1/>
    </>
  );
};

export default Curriculum;
