import default_user from "../../assests/images/default_user.png";

import "./toppers.css"

import Bhoomika from "../../assests/images/gallery/Bhoomika.png";
import Dhriti from "../../assests/images/gallery/Dhriti.png"
import Aishwarya from "../../assests/images/gallery/Aishwarya.png"
import Saniya from "../../assests/images/gallery/Saniya.png"
import Preethi from "../../assests/images/gallery/Preethi.png"
import Harshitha from "../../assests/images/gallery/Harshitha.jpeg"
import Lohith from "../../assests/images/gallery/Lohith.jpeg"
import Kiran from "../../assests/images/gallery/Kiran.jpeg"
import Nandana from "../../assests/images/gallery/Nandana.jpeg"
import Sanjay from "../../assests/images/gallery/Sanjay.jpeg"
import Shrunga from "../../assests/images/gallery/Shrunga.jpeg"
import Ramcharan from "../../assests/images/gallery/Ramcharan.jpeg"
import Deepika from "../../assests/images/gallery/Deepika.jpeg"


const ourtoppers = [ {
  id: '0',
  image: Bhoomika,
  name: 'Bhoomika R Pai',
  rank: '1st Rank',
  percentage: '100 %',
  year: '2022-2023',
  // color: '#3498db',
  color: '#e74c3c',
},
{
  id: '1',
  image: Dhriti,
  name: 'Dhriti G Pai',
  rank: '',
  percentage: '99 %',
  year: '2022-2023',
  color: '#e74c3c',
},
{
  id: '2',
  image: Aishwarya,
  name: 'Aishwarya K M',
  rank: '',
  percentage: '98 %',
  year: '2022-2023',
  color: '#e74c3c',
},
{
  id: '3',
  image: Saniya,
  name: 'Saniya Yadav',
  rank: '',
  percentage: '97 %',
  year: '2022-2023',
  color: '#e74c3c',
},
{
  id: '4',
  image: Preethi,
  name: 'Preethi',
  rank: '',
  percentage: '96 %',
  year: '2022-2023',
  color: '#e74c3c',
},
{
  id: '5',
  image: Shrunga,
  name: 'Shrunga K',
  percentage: '99.66%',
  year: '2021-2022',
  color: '#e74c3c',
  rank: '',
},
{
  id: '6',
  image: Sanjay,
  name: 'Sanjay K.S.',
  percentage: '98.24%',
  year: '2021-2022',
  color: '#e74c3c',
  rank: '',
},
{
  id: '7',
  image: Ramcharan,
  name: 'B. Ramcharan',
  percentage: '97.44%',
  year: '2021-2022',
  color: '#e74c3c',
  rank: '',
},
{
  id: '8',
  image: Deepika,
  name: 'Deepika M',
  percentage: '97.12%',
  year: '2021-2022',
  color: '#e74c3c',
  rank: '',
},
{
  id: '9',
  image: Nandana,
  name: 'Nandana m',
  percentage: '98.40%',
  year: '2017-2018',
  color: '#e74c3c',
  rank: '',
},
{
  id: '10',
  image: Harshitha,
  name: 'Harshitha n',
  percentage: '98.56%',
  year: '2014-2015',
  color: '#e74c3c',
  rank: '',
},
{
  id: '11',
  image: Kiran,
  name: 'Kiran H.B',
  percentage: '99%',
  year: '2014-2015',
  color: '#e74c3c',
  rank: '',
},
{
  id: '12',
  image: Lohith,
  name: 'Lohith V',
  percentage: '96.64%',
  year: '2014-2015',
  color: '#e74c3c',
  rank: '',
},
]

const Toppers = () => {

  return (
    <>
      {/* <h1 className="text-center test_header">Student Testimonial</h1> */}
      <div className="testimonial-listing">
        <div className="container">
          <div className="row">
            {ourtoppers.map(function(data) {
      return (
<div className="col-lg-4 col-md-6 col-sm-6">
              {/* Testimonials Box */}
              <div className="testimonial-box">
                <div
                  className="color color-theme1"
                  style={{ color: data.color, backgroundColor: data.color }}
                ></div>
                <figure className="image">
                  <img src={data.image} alt=""/>
                </figure>
                <h4 className="text-center">{data.name}</h4>

                <cite className="topperDetail">
                <p>
                    <h5>{data.rank}</h5>
                    {data.percentage}
                  </p>
                  <p className="topperpara">Class 10th <br />{data.year} <br />Karnataka State</p>
                  
                </cite>
              </div>
            </div>  
      )})}

                     
          </div>
        </div>
      </div>
    </>
  );
};

export default Toppers;