/* eslint-disable */
import M from "../components/ManagementPillars/m";
import F1 from "../components/f1/f1";
import Enquiry from "../components/Enquiry/Index";
import Navbar from "../components/navbar/Navbar";
import Managementherosec from "../components/managementherosec/Managementherosec";
// import Herosection from "../components/herosection/Herosection";

const ManagementsPage = () => {
  return (
    <>
    <Navbar />
      {/* <Herosection /> */}
      <Managementherosec />
      <M/>
      <Enquiry/>
      <F1/>

      
     
     
    </>
  );
};
 export default ManagementsPage;