import Gallery from "../components/gallery/Gallery";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Galleryherosec from "../components/galleryherosec/Galleryherosec";

const Galleries = () => {
  return (
    <>
      <Navbar />
      <Galleryherosec />
      <Gallery />
      <F1 />
    </>
  );
};

export default Galleries;
