import "./galleryherosec.css"

const Galleryherosec = () => {

  return (
    <>
    <section className="navherosection galleryherosec">  
      <div className="container fs-1 flex-column">
        <div className="galleryherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Gallery</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Galleryherosec;