import React, { Fragment } from "react";
import HeroSection from "../components/Hero-Section/HeroSection";
import HomeHerosec from "../components/homeHeroSec/HomeHeroSec";
import CompanySection from "../components/Company-section/Company";
import AboutUs from "../components/About-us/AboutUs";
import Courses from "../components/Courses-section/Courses";
import ChooseUs from "../components/Choose-us/ChooseUs";
import Features from "../components/Feature-section/Features";
import FreeCourse from "../components/Free-course-section/FreeCourse";
// import Testimonials from "../components/Testimonial/Testimonials";
import Newsletter from "../components/Newsletter/Newsletter";
import StudentsCarousel from "../components/studentsCarousel/StudentsCarousel";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";

const Home = () => {
  return (
    <Fragment>
      <Navbar />
      <HomeHerosec />
      <HeroSection />
      <StudentsCarousel />
      <AboutUs />
      <CompanySection />
      <ChooseUs />
      <Courses />
      <Features />
      <FreeCourse />
      {/* <Testimonials /> */}
      <Newsletter />
      <F1 />
    </Fragment>
  );
};

export default Home;
