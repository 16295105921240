import "./crmodelherosec.css"

const Crmodelherosec = () => {

  return (
    <>
    <section className="navherosection crmodelherosec">  
      <div className="container fs-1 flex-column">
        <div className="crmodelherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Curriculum Model</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Crmodelherosec;