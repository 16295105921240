import React from 'react'
import PrincipalDesk from "../components/principalDesck/PrincipalDesk"
import Navbar from '../components/navbar/Navbar'
import Enquiry from '../components/Enquiry/Index'
import F1 from '../components/f1/f1'
import Principalherosec from '../components/principalherosec/Principalherosec'

const PrincipalPage = () => {
  return (
    <div>
      <Navbar />
      <Principalherosec />
      <PrincipalDesk/>
      <Enquiry/>
      <F1/>
    </div>
  )
}

export default PrincipalPage

