import "./acrherosec.css"

const Acrherosec = () => {

  return (
    <>
    <section className="navherosection acrherosec">  
      <div className="container fs-1 flex-column">
        <div className="acrherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Areas of Curriculum</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Acrherosec;