import Toppers from "../Toppers/Toppers"

import img from "../../assests/images/gallery/achivement.png";


import "./achievers.css"


const Achievers = () => {
  

  return (
    <>
      <section className="achivmentsHead">
        <h1>Achievements of Macaulay School</h1>
      </section>

      <section className="achivmentsFstSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 style={{textAlign: "center"}}>Our Achievements</h3>
              <p className="achvcntjstfy">
              Our School has a rich academic excellence and holistic development, making notable strides in various arenas. Renowned for its commitment to nurturing well-rounded individuals, the school consistently achieves high academic standards, with students regularly excelling in national examinations and competitions. Through innovative teaching methodologies and a supportive learning environment, Macaulay School empowers students to reach their full potential, fostering a culture of critical thinking, creativity, and lifelong learning.
              </p>
              <p className="achvcntjstfy">Beyond academics, Macaulay School is celebrated for its vibrant extracurricular programs, where students showcase their talents and leadership skills. From winning accolades in sports tournaments to organizing impactful community service initiatives, students at Macaulay School embody the values of teamwork, compassion, and social responsibility. With a legacy of success and a commitment to holistic education.</p>
            </div>
            <div className="col-lg-6 achivmentsImgDiv">
              <img className="achivmentsImg" src={img} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="topperHeadsec">
        <h3 className="topperHead">Our Toppers</h3>
      </section>

      <section className="achiversSec">
        <Toppers />
      </section>
    </>
  )
}

export default Achievers;