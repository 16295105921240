import Infrastructure from "../components/infrastructure/Infrastructure";
import Heading from "../components/Heading/Heading";
import Enquiry from "../components/Enquiry/Index"
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Ifraherosec from "../components/ifraherosec/Ifraherosec";


const Infrastructures = () => {
  return (
    <>
      <Navbar />
      <Ifraherosec />
      <Heading />
      <Infrastructure />
      <Enquiry />
      <F1/>
    </>
  );
};

export default Infrastructures;
