import "./homeHerosec.css"

const HomeHerosec = () => {

  return (
    <>
    <section className="navherosection homeherosec">  
      <div className="container fs-1 flex-column">
        <div className="heroseccontent">
        <h3 className="homereroh3">Unlocking Potential, having Futures</h3>        
        <h1 className="homereroh1"> New Macaulay English School</h1>
        <h5 className="homereroh5"><q>Knowledge is Supreme</q></h5>
        </div>
      </div>
    </section>
    </>
  )
}

export default HomeHerosec;