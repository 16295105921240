import React, { Fragment } from "react";
import Contact from "../components/contact";
import Enquery from "../components/Enquiry/Index";
import F1 from "../components/f1/f1";
import Navbar from "../components/navbar/Navbar";
import Herosection from "../components/herosection/Herosection";


const Contacts = () => {
  return (
    <Fragment>
      <Navbar />
      <Herosection />
      <Contact />
      <Enquery/>
      <F1/>
    </Fragment>
  );
};

export default Contacts;