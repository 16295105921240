import React from "react";

import "./areasOfCurriculum.css";

import Enquiry from "../Enquiry/Index";

// import asurri1 from "../../assests/ImageCurriclm/acurri1.png";
import ascurri1 from "../../assests/ImageCurriclm/ascurri1.png";
import ascurri2 from "../../assests/ImageCurriclm/ascurri2.png";
import ascurri3 from "../../assests/ImageCurriclm/ascurri3.png";
import ascurri4 from "../../assests/ImageCurriclm/ascurri4.png";
import ascurri5 from "../../assests/ImageCurriclm/ascurri5.png";
import ascurri6 from "../../assests/ImageCurriclm/ascurri6.png";

const index = () => {
  return (
    <>
      <section className="admissionHead">
        <h1>Our Curriculum Facets</h1>
      </section>

      <section className="light">
        <div className="container py-2">
          <article className="postcard light blue">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={ascurri1} alt="Image Title" />
            </a>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title blue">
                Holistic Language Proficiency
              </h1>
              {/* <div className="postcard__subtitle small">
					<span>Academic Disciplines</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we believe in fostering holistic language
                  proficiency among our students to prepare them for success in
                  an interconnected world. Our approach goes beyond traditional
                  language instruction, encompassing not only grammar and
                  vocabulary but also cultural understanding, effective
                  communication, and critical thinking skills. Through immersive
                  language experiences, interactive dialogues, and real-world
                  applications, students develop the ability to express
                  themselves fluently and confidently in various contexts.
                </p>

                <p>
                  Moreover, our holistic language proficiency program integrates
                  language learning into every facet of student life, from
                  literature appreciation to cross-cultural exchanges. By
                  embracing linguistic diversity and promoting multilingualism,
                  we create a dynamic learning environment where students gain a
                  deep appreciation for languages and cultures. Through this
                  comprehensive approach, we equip our students with the
                  linguistic and cultural competencies needed to thrive in a
                  globalized society.
                </p>
              </div>
            </div>
          </article>
          <article className="postcard light red">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={ascurri2} alt="Image Title" />
            </a>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title red">Holistic Approach to Math</h1>
              {/* <div className="postcard__subtitle small">
					<span>Appraisal</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we believe in nurturing a holistic approach to
                  math education, emphasizing not just rote memorization but
                  also conceptual understanding and practical application.
                  Through hands-on activities, problem-solving challenges, and
                  real-world scenarios, we engage students in exploring the
                  interconnectedness of mathematical concepts and their
                  relevance in everyday life.
                </p>

                <p>
                  Our holistic approach encourages critical thinking and fosters
                  a deep appreciation for the beauty of mathematics. By
                  integrating mathematical concepts across various subjects and
                  encouraging creativity in problem-solving, we empower students
                  to develop versatile skills that extend far beyond the
                  classNameroom. With this comprehensive foundation, our
                  students are equipped to excel academically and thrive in
                  diverse fields requiring mathematical reasoning and analytical
                  thinking.
                </p>
              </div>
            </div>
          </article>
          <article className="postcard light green">
            <a className="postcard__img_link" href="#">
              <img className="postcard__img" src={ascurri3} alt="Image Title" />
            </a>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title green">
                Integrated Science Exploration
              </h1>
              {/* <div className="postcard__subtitle small">
				<span>Educational Goals</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we prioritize integrated science exploration to
                  foster proficiency in scientific inquiry and understanding.
                  Through hands-on experiments, interdisciplinary projects, and
                  real-world applications, students delve into the
                  interconnectedness of scientific disciplines. Our curriculum
                  emphasizes critical thinking, problem-solving, and
                  collaborative skills, empowering students to tackle complex
                  scientific challenges with confidence.
                </p>

                <p>
                  Furthermore, our integrated science exploration program
                  extends beyond the classNameroom, incorporating field trips,
                  guest speakers, and community partnerships. By engaging with
                  professionals in various scientific fields and exploring local
                  ecosystems, students gain practical experience and a deeper
                  appreciation for the natural world. Through this comprehensive
                  approach, we nurture a generation of scientifically literate
                  individuals prepared to make meaningful contributions to
                  society.
                </p>
              </div>
            </div>
          </article>
          <article className="postcard light yellow">
            <div className="postcard__img_link">
              <img className="postcard__img" src={ascurri4} alt="Image Title" />
            </div>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title yellow">
                Interdisciplinary Social Studies
              </h1>
              {/* <div className="postcard__subtitle small">
				<span>Sociocultural Environment</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we embrace an integrated approach to science
                  exploration, immersing students in hands-on experiences that
                  span multiple scientific disciplines. Through
                  interdisciplinary projects, laboratory investigations, and
                  real-world applications, students delve into the
                  interconnectedness of scientific concepts and their relevance
                  to society.
                </p>

                <p>
                  Our integrated science curriculum fosters critical thinking,
                  problem-solving, and inquiry-based learning, preparing
                  students to tackle complex scientific challenges with
                  confidence. By exploring the intersections between biology,
                  chemistry, physics, and environmental science, students gain a
                  comprehensive understanding of the natural world and develop
                  the skills needed to thrive in an increasingly scientific and
                  technological society.
                </p>
              </div>
            </div>
          </article>
          <article className="postcard light blue">
            <div className="postcard__img_link">
              <img className="postcard__img" src={ascurri5} alt="Image Title" />
            </div>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title blue">
                Fostering of Creative Ingenuity
              </h1>
              {/* <div className="postcard__subtitle small">
				<span>Continuing Education</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we are committed to fostering interdisciplinary
                  social studies proficiency among our students, preparing them
                  to understand and engage with the complexities of the modern
                  world. Through a comprehensive curriculum that integrates
                  history, geography, civics, and economics, students gain a
                  holistic understanding of society and its diverse cultures.
                  Our approach emphasizes critical thinking, research skills,
                  and empathy, empowering students to analyze complex social
                  issues and become informed global citizens.
                </p>

                <p>
                  Furthermore, our interdisciplinary social studies program
                  extends beyond the classNameroom, incorporating experiential
                  learning opportunities such as community service projects,
                  cultural exchanges, and simulations. By engaging with
                  real-world contexts and diverse perspectives, students develop
                  a deeper appreciation for the interconnectedness of global
                  issues and the importance of civic engagement. Through this
                  comprehensive approach, we equip our students with the
                  knowledge, skills, and values needed to actively participate
                  in shaping a more just and equitable society.
                </p>
              </div>
            </div>
          </article>
          <article className="postcard light red">
            <div className="postcard__img_link">
              <img className="postcard__img" src={ascurri6} alt="Image Title" />
            </div>
            <div className="postcard__text t-dark">
              <h1 className="postcard__title red">
                Advocacy for Holistic Wellness
              </h1>
              {/* <div className="postcard__subtitle small">
				<span>Employability Skills</span>
				</div> */}
              <div className="postcard__bar"></div>
              <div className="postcard__preview-txt">
                <p>
                  At Our School, we advocate for holistic wellness by
                  prioritizing the physical, emotional, and mental well-being of
                  our students. Through comprehensive health education programs,
                  mindfulness practices, and access to support services, we
                  strive to create a nurturing environment where students feel
                  valued and supported in all aspects of their lives.
                </p>

                <p>
                  Our advocacy for holistic wellness extends beyond the
                  individual to encompass the entire school community. By
                  promoting a culture of empathy, respect, and inclusivity, we
                  foster a sense of belonging and connectedness that contributes
                  to the overall well-being of our students. Through
                  collaborative efforts with parents, faculty, and staff, we
                  work towards creating a school environment that prioritizes
                  the holistic wellness of every student, laying the foundation
                  for lifelong health and happiness.
                </p>
              </div>
            </div>
          </article>
        </div>
      </section>

      <div>
        <Enquiry />
      </div>
    </>
  );
};

export default index;
