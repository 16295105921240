import "./facilityherosec.css"

const Facilityherosec = () => {

  return (
    <>
    <section className="navherosection facilityherosec">  
      <div className="container fs-1 flex-column">
        <div className="facilityherocnt">
        <h1>New Macaulay English School</h1>
        <h2><span><q>Knowledge is Supreme</q></span></h2>
        <h1>Our Facilities</h1>
        </div>
      </div>
    </section>
    </>
  )
}

export default Facilityherosec;