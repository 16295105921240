import Marquee from "react-fast-marquee";

import img from "../../assests/images/gallery/Bhoomika.png"
import img1 from "../../assests/images/gallery/Dhriti.png"
import img2 from "../../assests/images/gallery/Aishwarya.png"
import img3 from "../../assests/images/gallery/Saniya.png"
import img4 from "../../assests/images/gallery/Preethi.png"
import Harshitha from "../../assests/images/gallery/Harshitha.jpeg"
import Lohith from "../../assests/images/gallery/Lohith.jpeg"
import Kiran from "../../assests/images/gallery/Kiran.jpeg"
import Nandana from "../../assests/images/gallery/Nandana.jpeg"
import Sanjay from "../../assests/images/gallery/Sanjay.jpeg"
import Shrunga from "../../assests/images/gallery/Shrunga.jpeg"
import Ramcharan from "../../assests/images/gallery/Ramcharan.jpeg"
import Deepika from "../../assests/images/gallery/Deepika.jpeg"


import "./studentsCarousel.css";


const toppers = [ {
  id: '0',
  image: img,
  name: 'Bhoomika R Pai',
  percentage: '100 %',
  year: '2022-2023'
},
{
  id: '1',
  image: img1,
  name: 'Dhriti G Pai',
  percentage: '99 %',
  year: '2022-2023'
},
{
  id: '2',
  image: img2,
  name: 'Aishwarya K M',
  percentage: '98 %',
  year: '2022-2023'
},
{
  id: '3',
  image: img3,
  name: 'Saniya Yadav',
  percentage: '97 %',
  year: '2022-2023'
},
{
  id: '4',
  image: img4,
  name: 'Preethi',
  percentage: '96 %',
  year: '2022-2023'
},
{
  id: '5',
  image: Shrunga,
  name: 'Shrunga K',
  percentage: '99.66%',
  year: '2021-2022',
},
{
  id: '6',
  image: Sanjay,
  name: 'Sanjay K.S.',
  percentage: '98.24%',
  year: '2021-2022',
},
{
  id: '7',
  image: Ramcharan,
  name: 'B. Ramcharan',
  percentage: '97.44%',
  year: '2021-2022',
},
{
  id: '8',
  image: Deepika,
  name: 'Deepika M',
  percentage: '97.12%',
  year: '2021-2022',
},
{
  id: '9',
  image: Nandana,
  name: 'Nandana m',
  percentage: '98.40%',
  year: '2017-2018',
},
{
  id: '10',
  image: Harshitha,
  name: 'Harshitha n',
  percentage: '98.56%',
  year: '2014-2015',
},
{
  id: '11',
  image: Kiran,
  name: 'Kiran H.B',
  percentage: '99%',
  year: '2014-2015',
},
{
  id: '12',
  image: Lohith,
  name: 'Lohith V',
  percentage: '96.64%',
  year: '2014-2015',
},

];

const StudentsCarousel = () => {
  return (
    <>
      
        <section className="admissionMarquee topmarq">
          <h3 className="topCrslHead">Our Toppers</h3>
        <Marquee>
        {toppers.map(function(data) {
      return (
        <div className="card studentCard inlblock">
            <img className="img" src={data.image} alt="" />
            <span>{data.name}</span>
            <p className="percentage">{data.percentage}</p>
            <p className="year">{data.year}</p>
          </div>
          )
        })}
        </Marquee>
      </section>
      

      
    </>
  );
};

export default StudentsCarousel;
