import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo from "../../assests/images/navBarImages/MHS.png"
import './f1.css'

const F1 = () => {
    return (
        <div>
            <MDBFooter>
                <section className='footerSec'>
                    <MDBContainer className='text-center text-md-start'>
                        <MDBRow>
                            <MDBCol md="6" lg="3">
                                <div className="footerlogo">
                                    <img src={logo} alt="Company Logo" width='170px' className="mb-3 lo"/>
                                    <p className='my-p1-class'>Follow us on Social Media</p>

                                    <div className='icon'>
                                        <a href='https://facebook.com/share/bRDUkRszHg7sRRcf/?mibextid=oFDknk' className='socialiconf'>

                                            <MDBIcon fab icon="facebook-square" className="text-primary" />
                                        </a>
                                        <a href='https://instagram.com/nmehs7?utm_source=qr&igsh=d2x4NXZhajQzaHFk' className='socialicon'>

                                            <MDBIcon fab icon="instagram-square" className="custom-instagram-icon" />
                                        </a>
                                        <a href='https://youtube.com/@NewMacaulayHighSchool?si=M8TEhnGL7EE6CRxk' className='socialicon'>
                                            <MDBIcon fab icon="youtube-square" className="text-danger" />
                                        </a>
                                    </div>
                                </div>
                            </MDBCol>


                            <MDBCol md="6" lg="2" className='mb-4 black-text ql' style={{ marginTop: '25px',}}>
                                <h6 className='text-uppercase fw-bold mb-4 black-text'>Quick Links</h6>
                                <p className='my-custom-class'>
                                    <a href='/' className='no-underline text-black'>
                                        Home
                                    </a>
                                </p>
                                <p className='my-custom-class'>
                                    <a href='/about' className='no-underline text-black'>
                                        About Us
                                    </a>
                                </p>
                                <p className='my-custom-class'>
                                    <a href='/infrastructures' className='no-underline text-black'>
                                        Infrastructure
                                    </a>
                                </p>

                            </MDBCol>



                            <MDBCol md="6" lg="2" className='mb-4 black-text' style={{ marginTop: '25px',}}>
                                <h6 className='text-uppercase fw-bold mb-4 black-text'>info links</h6>
                                <p className='my-custom-class'>
                                    <a href='/Academics' className='no-underline text-black'>
                                        Academics
                                    </a>
                                </p>
                                <p className='my-custom-class'>
                                    <a href='/Contacts' className='no-underline text-black'>
                                        Contact
                                    </a>
                                </p>
                                <p className='my-custom-class'>
                                    <a href='https://internal.newmacaulayenglishschool.com/authentication/index' className='no-underline text-black'>
                                        School IMS
                                    </a>
                                </p>

                            </MDBCol>

                            <MDBCol md="6" lg="5" className='mb-4 black-text' style={{ marginTop: '25px',}}>
                                <h6 className='text-uppercase fw-bold mb-4 black-text'>Contact</h6>
                                <p className='my-custom-class'>
                                    <MDBIcon icon="building" className="me-3 black-icon" />
                                    Kudlu Gate, Hosapalya Main Road, Banglore- 560068                                </p>
                                <p className="my-custom-class">
                                    <MDBIcon icon="envelope" className="me-3 black-icon" />
                                    macaulayschool2010@gmail.com
                                </p>
                                <p className='my-custom-class'>
                                    <MDBIcon icon="phone" className="me-3 black-icon" />  080-49524676
                                </p>
                                <p className='my-custom-class'>
                                    <MDBIcon icon="mobile" className="me-3 black-icon" /> +91-9739156824
                                </p>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>
            </MDBFooter>
            <div className="copyrt" >
                <p className="copyrtline">&copy; Macaulay Group of Schools | Designed & Developed by Drighna Technology pvt Ltd.</p>
            </div>
        </div>
    )
}

export default F1