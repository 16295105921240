import "./principal.css";
import Principal from "../../assests/images/gallery/principal.jpeg";

const PrincipalDesk = () => {
  return (
    <>
      <section classNameName="team-pg-area section-padding">
        <div className="container">
          <div className="team-single-wrap">
            <div className="team-info-wrap">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="team-info-img">
                    <img src={Principal} alt="" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="team-info-text">
                    <h2>Dipika Pandey</h2>
                    <p className="principalpara">
                      Principal of New Macaulay English School
                    </p>
                    <ul>
                      <li>
                        Qualification:{" "}
                        <span>Masters in Mathematics and M.Ed</span>
                      </li>
                      <li>
                        Experience:<span>10+ Years</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="team-exprience-area team-widget">
              <div className="row">
                <div className="col-12">
                  <div className="exprience-wrap">
                    <h2>From Principal Desk</h2>
                    <p className="principalpara">
                      Dear Students, Parents, and Guardians,
                    </p>
                    <p className="principalpara">
                      It is with great pleasure and excitement that I extend a
                      warm welcome to all members of our school community. As
                      the principal of New Macaulay English School, I am
                      honoured to lead such a vibrant and diverse group of
                      individuals dedicated to academic excellence, personal
                      growth, and community engagement.
                    </p>
                    <p className="principalpara">
                      At New Macaulay English School, we believe in fostering a
                      nurturing environment where every student feels valued,
                      supported, and empowered to reach their full potential.
                      Our dedicated team of educators is committed to providing
                      a rigorous and engaging curriculum that challenges
                      students to think critically, collaborate effectively, and
                      become lifelong learners.
                    </p>
                    <p className="principalpara">
                      We also recognize the importance of fostering a strong
                      partnership between home and school. We encourage open
                      communication and collaboration between parents,
                      guardians, and our staff to ensure the success of every
                      student.
                    </p>
                    <p className="principalpara">
                      As we embark on this new academic year, I am confident
                      that together, we will continue to uphold the traditions
                      of excellence that define our school while embracing
                      innovation and growth. I look forward to working closely
                      with each of you to make this school year a rewarding and
                      memorable experience for all.
                    </p>
                    <p className="principalpara">
                      Thank you for entrusting us with your child's education.
                      Together, we will make a difference.
                    </p>
                    <p className="principalpara">
                      Warm regards,
                      <br />
                      Dipika Pandey <br />
                      Principal of New Macaulay English School
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrincipalDesk;
