import React from 'react';
import './MovieNight.css'; // Import CSS file
import Swiper from 'swiper'; // Import Swiper JS if needed
import 'swiper/swiper-bundle.css'; // Import Swiper CSS styles
import academicFacilitiesImage from '../../assests/images/ac.png';
import sports from '../../assests/images/sports.png';
import facilities from '../../assests/images/facilities.svg';
import others from '../../assests/images/others.png';


class MovieNightSection extends React.Component {
    componentDidMount() {
      // Initialize Swiper
      var swiper = new Swiper(".swiper", {
        effect: "cards",
        grabCursor: true,
        initialSlide: 2,
        speed: 500,
        loop: true,
        rotate: true,
        mousewheel: {
          invert: false,
        },
      });
    }

  render() {
    return (
      <section class='mn'>
        <div className="content">
          <div className="info">
          <h4 style={{ color: 'Black' }}>Explore Our Facilities</h4>

            <p>
              Explore Our Facilities
               We take immense pride in offering a diverse range of facilities that enrich the
             educational journey of our students. Our campus is designed to foster a supportive
              and engaging environment where students can thrive academically, physically,
               and creatively. 

             
            </p>
            <a className='movienightatag' href="/Contacts" target="_blank">
            <button className="btn moviesnightbtn">Join</button>
            </a>
          </div>
          <div class="swiper">
          <div class="swiper-wrapper">
          <div className="swiper-slide" style={{ padding: '20px' }}>
              <img src={academicFacilitiesImage} alt="Academic Facilities" />
              <div class="overlays">
            
                <h2>Academic Facilities</h2>
              </div>
            </div>

            <div class="swiper-slide">
              <img
                class="img-position"
                src={sports}
                alt="" />
              <div class="overlays">
              
                <h2>Sports and Recreation Facilities</h2>
              </div>
            </div>

            <div class="swiper-slide">
              <img
                src={facilities}
                alt="" />
              <div class="overlays">
              
                <h2>Computer Lab Facilities</h2>
              </div>
            </div>

            <div class="swiper-slide">
              <img
                src={others}
                alt="" />
              <div class="overlays">
            
                <h2>Additional Facilities</h2>
              </div>
            </div>

            
          </div>
        </div>
      </div>

     
    </section>

   
    );
  }
}

export default MovieNightSection;
