import React from 'react';
import './curri.css'; // Import your custom CSS file
import bgroundImage from '../../assests/schoolimg/bb5.jpeg'
import a from '../../assests/images/about/adaptability (2) trans.png'
import moto from '../../assests/images/gallery/learning.png'
import creativity from '../../assests/gallryimg/scl1.png'
import ec from '../../assests/images/about/Effective communication (1).png'
import em from '../../assests/images/about/Empathy and understanding (1).png'
import ca from '../../assests/images/about/Creativity and Innovation.png'
import m from '../../assests/images/about/Motivation and inspiration.png'
import commununi from '../../assests/images/gallery/s1.jpeg'
import u from '../../assests/schoolimg/em.jpeg'
import adapt from '../../assests/schoolimg/adaptsemi.jpeg'

const Curri = () => {
    return (
        <>
            <div className="infra-title">
                <h2 className='infra-title-head'>Curriculum Framework</h2>
            </div>

            <div className="container">
                <div className="row pt-5 m-auto">
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 1 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${adapt})` }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={a} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Adaptability</h4>
                                <p className="card-text">"Our school's policy on adaptability emphasizes creating an inclusive and flexible learning environment that promotes resilience and prepares students to thrive in diverse situations"</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 2 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${commununi})` }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={ec} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Effective Communication</h4>
                                <p className="card-text">"Our school's policy on effective communication underscores the importance of clear, respectful, and inclusive communication practices to foster understanding."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 3 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${u})` }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={em} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Empathy And Understanding</h4>
                                <p className="card-text">"Our school's policy on empathy and understanding emphasizes fostering a supportive and inclusive environment through diverse perspectives."</p>
                            </div>
                        </div>
                    </div>
                    {/* Next Line with 3 More Cards */}
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 4 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${creativity})` }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={ca} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Creativity & Innovation</h4>
                                <p className="card-text">"Our school's policy on creativity and innovation focuses on encouraging imaginative thinking, embracing experimentation, and fostering a culture that values bold ideas and innovative solutions."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 5 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${moto})`, }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={m} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Motiviation And Inspiration</h4>
                                <p className="card-text">"Our school's policy on motivation and inspiration centers on cultivating a positive and inspiring environment that encourages students to set goals, stay focused, and pursue their passions with enthusiasm."</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 pb-3">
                        {/* Card 6 */}
                        <div className="card card-custom bg-white border-white border-0">
                            <div className="card-custom-img" style={{ backgroundImage: `url(${bgroundImage})`, }}></div>
                            <div className="card-custom-avatar">
                                <img className="img-fluid" src={a} alt="Avatar" />
                            </div>
                            <div className="card-body" style={{ overflowY: 'auto' }}>
                                <h4 className="card-title">Building Relationship</h4>
                                <p className="card-text">"Our school's policy on building relationships prioritizes creating a supportive and connected community by fostering trust, communication, and collaboration among students, educators, and stakeholders."</p>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
}

export default Curri;
